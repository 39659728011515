import React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/layout'
import Head from '../components/head'

const NotFoundPage = () => (
  <Layout>
    <Head
      title="404: Not found"
    />
    <h1 className='mt-12'>NOT FOUND</h1>
    <img src="https://media4.giphy.com/media/n6uToGd0j3vdnKCq63/giphy.gif?cid=ecf05e470azcvm3gjjvpmn3uwsr1k0zjdmb56ra56i0llm55" alt='Rocket explodes' width="720" height="480" className="giphy-embed rounded-md" />
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
